
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
.wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    user-select: none;

    cursor: pointer;

    &.inline {
        display: inline-flex;
    }

    &.noSpace {
        justify-content: initial;
    }


    &.fitContent {
        width: fit-content;
    }
}

.label {
    &:last-child {
        margin-left: 8px;
    }

    &:first-child {
        margin-right: 8px;
    }
}

.checkbox {
    width: 16px;
    height: 16px;
}
