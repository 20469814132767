
                                        @use '~@viuch/shared/styles/breakpoints' as *;
                                        @use '~@viuch/shared/styles/typography' as *;
                                        @use '~@viuch/shared/styles/utils' as *;
                                    
$icons: [
    angle,
    bisection,
    circle,
    circle-circumscribed,
    circle-inscribed,
    clear,
    cursor,
    dot,
    ellipse,
    equal-angles,
    equal-segments,
    equal-segments-2,
    fragment,
    fragment-dashed,
    height,
    history-redo,
    history-undo,
    label-angle,
    label-dot,
    label-right-angle,
    label-segment,
    line,
    median,
    middle-line,
    minus,
    parallel,
    parallel-fragment,
    parallelogram,
    perpendicular,
    plus,
    polygon5,
    polygon6,
    polygon7,
    polygon8,
    polygon9,
    rect,
    remove,
    rhombus,
    right-angle,
    square,
    tangent,
    tangent-throw-point,
    trapeze,
    triangle,
    triangle-right,
    vector,
    vector-dashed,
    yes,
    no
];

.icon {
    background-color: currentColor;
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
    mask-origin: content-box;

    @each $icon in $icons {
        &:global(.#{$icon}) {
            mask-image: url("./#{$icon}.svg");
        }
    }
}
